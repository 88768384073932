import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../components/Layout';
import Cover from '../../components/Elements/Cover';
import PageSection from '../../components/Elements/PageSection';
import GetReady from '../../components/Parts/GetReady';
import Row, { Col } from '../../components/Elements/Grid';
import Image from '../../components/Elements/Image';
import withTranslations from '../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('managedHosting');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover nowrap>
        <Image
          image="headerManagedHosting"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center 30%' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('title')}</h1>
            <p>{t('intro.p1')}</p>
            <p>{t('intro.p2')}</p>
            <p>{t('intro.p3')}</p>
          </PageSection>
          <PageSection grid slide>
            <h2 className="text-center">{t('normal.title')}</h2>
            <Row>
              <Col>
                <h3>{t('normal.allin.title')}</h3>
                <p>{t('normal.allin.description')}</p>
              </Col>
              <Col>
                <h3>{t('normal.preInvestment.title')}</h3>
                <p>{t('normal.preInvestment.description')}</p>
              </Col>
              <Col>
                <h3>{t('normal.devops.title')}</h3>
                <p>{t('normal.devops.description')}</p>
              </Col>
            </Row>
          </PageSection>
          <GetReady />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
