import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../../../i18n/Link';

const LinkButton = ({
  to, children, size, external, className,
}) => {
  const classList = classNames('link-button', size, className);

  // If a button is requested, render a button.
  if (typeof to === 'function') {
    return (
      <button
        tabIndex={-1}
        type="button"
        onClick={to}
        onKeyDown={to}
        className={classList}
        aria-label={children}
      >
        <span>{children}</span>
      </button>
    );
  }

  if (external) {
    return (
      <a href={to} className={classList} aria-label={children}>
        <span>{children}</span>
      </a>
    );
  }

  const linkTo = to.substr(to.length - 1) !== '/' ? `${to}/` : to;

  return (
    <Link to={linkTo} className={classList} aria-label={children}>
      <span>{children}</span>
    </Link>
  );
};

LinkButton.defaultProps = {
  size: null,
  external: false,
  className: null,
};

LinkButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm']),
  external: PropTypes.bool,
  className: PropTypes.string,
};

export default LinkButton;
