import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Row = ({ className, id, children }) => (
  <>
    {id !== false && (
      <a className="anchor" id={id} /> // eslint-disable-line
    )}
    <div className={classNames('grid-row', className)}>{children}</div>
  </>
);

Row.defaultProps = {
  id: false,
  className: false,
};

Row.propTypes = {
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};

const Col = ({
  filled, video, story, className, children,
}) => (
  <div className={classNames('grid-col', className, { video, story, filled })}>{children}</div>
);

Col.defaultProps = {
  filled: false,
  story: false,
  video: false,
  className: false,
};

Col.propTypes = {
  filled: PropTypes.bool,
  video: PropTypes.bool,
  story: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};

export { Col };
export default Row;
