import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Row, { Col } from '../../Elements/Grid';
import LinkButton from '../../Elements/LinkButton';
import PageSection from '../../Elements/PageSection';
import Image from '../../Elements/Image';

const Part = ({ title, image }) => {
  const { t } = useTranslation('componentGetReady');

  return (
    <PageSection image className="get-ready">
      <Row>
        <Col filled>
          <Image
            image={image}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Col>
        <Col>
          <h2>{title || t('defaultTitle')}</h2>
          <p>{t('description.p1')}</p>
          <p>{t('description.p2')}</p>
          <p>{t('description.p3')}</p>
          <LinkButton to="/contact">{t('callToAction')}</LinkButton>
        </Col>
      </Row>
    </PageSection>
  );
};

Part.defaultProps = {
  title: false,
  image: 'footerGetReady',
};

Part.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  image: PropTypes.string,
};

export default Part;
